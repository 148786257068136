import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/javascript-constructor",
  "date": "2015-08-07",
  "title": "JAVASCRIPT & CONSTRUCTOR",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "ECMAScript is a object based language. An object has a built-in property named constructor."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The `}<strong parentName="p">{`constructor`}</strong>{` will reference the function which made the object.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Foo();

var foo = new Foo();

foo.constructor === Foo; // true
`}</code></pre>
    <h2>{`How is constructor created?`}</h2>
    <p>{`When you created the `}<inlineCode parentName="p">{`foo`}</inlineCode>{` object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
var foo = new Foo();

`}</code></pre>
    <p>{`JavaScript engine creates a new functon object from your declaration. Togther with this, your `}<strong parentName="p">{`prototype`}</strong>{` is created as well.`}</p>
    <p>{`This default value the prototype is an object with property constructor, which is set to the function itself.`}</p>
    <p>{`So, when new `}<strong parentName="p">{`Foo`}</strong>{` is called, the `}<inlineCode parentName="p">{`Foo.prototype`}</inlineCode>{` becomes `}<inlineCode parentName="p">{`__proto__`}</inlineCode>{` and the constructor becomes accessible from the object:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
function Foo() { }

var foo = new Foo();

console.log(

  foo.hasOwnProperty('constructor'), // false
  Foo.constructor === Function, // true
  Foo.prototype.hasOwnProperty('constructor') // true

);

`}</code></pre>
    <h2>{`Prototype & the constructor`}</h2>
    <p>{`If we replace the prototype, we will end up losing the constructor:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
function Foo() { }

var foo = new Foo();

foo.constructor === Object; // false
foo.constructor === Foo; // true

Foo.prototype = {};

foo = new Foo();

foo.constructor === Object; // true

`}</code></pre>
    <p>{`What happens here is: when we created the `}<strong parentName="p">{`foo`}</strong>{` object, the JavaScript engine creates an object and sets its constructor to `}<inlineCode parentName="p">{`Foo`}</inlineCode>{`.
But we overrode the prototype of the `}<inlineCode parentName="p">{`Foo`}</inlineCode>{` with `}<inlineCode parentName="p">{`{}`}</inlineCode>{`. This object (`}<inlineCode parentName="p">{`{}`}</inlineCode>{`), does not have a `}<inlineCode parentName="p">{`constructor`}</inlineCode>{`, so the compiler falls back to its `}<inlineCode parentName="p">{`__proto__`}</inlineCode>{`, using the native `}<inlineCode parentName="p">{`Object.prototype.constructor`}</inlineCode>{`. `}</p>
    <h2>{`Preserving constructor`}</h2>
    <p>{`Additional code is usually required to prevent it from being overwritten. If you intend to keep it correct, then put it into the new prototype, like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function Foo() { }

Foo.prototype = { };

var foo = new Foo();

foo.constructor === Foo; // false

Foo.prototype = { constructor: Foo };

foo = new Foo();

foo.constructor === Foo; // true
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      